import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import LoginForm from '../../../../components/Login/Login';
import * as styles from '../articles.module.scss';
import ArticleImg from '../../../../assets/IMG/freebies/hybrid-working/1.png';
import articlePDF from "../../../../assets/pdfs/hybrid working.pdf";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { API_BaseURL } from '../../../../Services/ApiRoutes';
import { Link } from 'gatsby';

interface Props {
  location: string;
}

const HybridWorking: React.FC<Props> = ({ location }) => {
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const fifthRef = useRef(null);
  const shareURL: string = location ? `${(API_BaseURL === "/api/" && !location.toLowerCase().includes("beta") ? "https://primehr.co.uk/" : "https://beta.primehr.co.uk/")}Articles/Hybrid-Working` : "";
  const scroll = (ref: any) => ref.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Articles :: RETURNING TO THE OFFICE WITH A HYBRID TEAM?</title><meta charSet='utf-8' />
        <meta property="og:description" content="It goes without saying that the pandemic has created the greatest
            workplace transformation of our lifetime, quickly changing the way
            you and your team work. And possibly forever. We know it has its
            challenges but there are huge benefits long term and once you get
            the right formula it brings incredible performance from your team
            and increases outputs exponentially for the organisation." />
        <meta property="og:image" content={ArticleImg} />
        <meta property="og:title" content="PrimeHR :: 5 Things You Don't Want to Get Wrong with Hybrid Working" />
        <meta property="og:site_name" content="PrimeHR" />
        <meta property="og:url" content="https://primehr.co.uk/Articles/Hybrid-Working" />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "/Articles", Text: "Articles" },
        { To: "", Text: "Hybrid Working" }
      ]} />

      <div className={styles.Section}>
        <div className={styles.SectionInner}>
          <h1>RETURNING TO THE OFFICE WITH A HYBRID TEAM?</h1>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            {/* <span>Written by <Link to="https://www.linkedin.com/in/nikki-hufton-bsc-hons-mcipd-fcmi-mnlp-a7528854/">Nikki Hufton</Link></span> */}
          </div>

          <div className={styles.SectionImage}>
            <img loading={"lazy"} src={ArticleImg} />
          </div>

          <h2>HERE ARE 5 THINGS YOU DON'T WANT TO GET WRONG</h2>

          <ul>
            <li><a onClick={() => scroll(firstRef)}>1. Create a Hybrid Working Policy</a></li>
            <li><a onClick={() => scroll(secondRef)}>2. Make sure you're treating employees fairly</a></li>
            <li><a onClick={() => scroll(thirdRef)}>3. Rethink how you manage performance</a></li>
            <li><a onClick={() => scroll(fourthRef)}>4. Focus on team happiness</a></li>
            <li><a onClick={() => scroll(fifthRef)}>5. Make sure you're tech-ready</a></li>
          </ul>

          <p>It goes without saying that the pandemic has created the greatest
            workplace transformation of our lifetime, quickly changing the way
            you and your team work. And possibly forever. We know it has its
            challenges but there are huge benefits long term and once you get
            the right formula it brings incredible performance from your team
            and increases outputs exponentially for the organisation.
          </p>
          <p>
            Within a week, businesses like yours had to quickly adapt to working
            remotely. It was crazy, stressful and, honestly, a little bit scary.
            But on the whole, it’s likely that you and your team did an amazing
            job; quickly adjusting to working from home with surprisingly positive
            results.
          </p>
          <p>
            Now there seems to be light at the end of the tunnel. The world is
            slowly returning to some kind of 'normal', which means you can go
            back to working in the office like you did pre-covid. Right?
            Maybe not.
          </p>
          <p>
            You’re probably reading this because your business is considering, or
            has decided, to maintain that element of increased flexibility, by
            giving your employees the choice to continue working from home, in
            the office, or a mixture of both and so creating a new type of hybrid
            workforce.
          </p>
          <p>
            The benefits of a hybrid workforce can be huge. But, of course, it can
            also have downsides that you need to both be aware of and manage.
            That’s why we’ve created this guide to help you.
            Inside, you’ll discover five of the most important things to consider
            and get right.
            Let’s get started.
          </p>

          <h3 ref={firstRef}>1. CREATE A HYBRID WORKING POLICY</h3>
          <p>
            With any sort of change in the
            business, there are always lots of
            unanswered questions. This can easily
            lead to confusion or conflict with your
            team, which no one wants.
          </p>
          <p>
            You really need to think about all
            possible scenarios and create a formal
            hybrid working policy or atleast add it
            to your Home Working policy, if you
            have one, that becomes the new
            working blueprint for your business.
          </p>
          <p>
            Think about how you’ll manage
            requests for flexible working; how you’ll
            ensure the office is manned (if it needs
            to be); how many hours a week or
            month each employee will need to
            spend in the office (if any), and how this
            will be communicated back to you, so
            you know what’s going on and where
            your team will be.
          </p>
          <p>
            You need to consider all of this, as well
            as additional costs, like HMRC rules,
            expense claims, plus more, before you
            go ahead with a new policy so that
            everyone will know where they stand.
          </p>
          <p>
            This is all about setting expectations to
            help protect you, your company, your
            relationship with your employees and
            your employee happiness.
          </p>

          <h3 ref={secondRef}>2. MAKE SURE YOU’RE TREATING EMPLOYEES FAIRLY</h3>
          <p>
            With many of us already experiencing some
            form of remote working, you may have
            noticed how easy it can be to assume
            everything’s going well, when behind the
            scenes someone isn’t happy.
          </p>
          <p>
            First and foremost, you must ensure that
            everyone in your business knows that, just as
            it would be in the office, communication
            channels to you and/or line managers are
            always open. You may even want to have an
            emoji you use on instant messaging that
            means, could I have a quick word.
          </p>
          <p>
            Remember when colleagues would just have
            a chat grabbing a coffee or collar you in the
            corridor?
          </p>
          <p>
            Make sure that people know the best ways to
            voice concerns or complaints, where to go to
            ask questions and who to speak to should
            they have any problems.
          </p>
          <p>
            When your people are frequently working
            remotely, it can make it more difficult to have
            a tricky conversation or speak to the right
            person when something isn’t as it should be.
            By outlining clear instructions for such an
            event, you should make it easier for them.
            And, if you find that some employees are
            quieter than usual, make the time to check in
            on them just to be sure.
          </p>
          <p>
            Make it clear to everyone that, although they
            may be working away from the office, they
            are still very much in your mind. They will be
            considered for all of the usual things, such as
            projects, promotions, or additional roles as
            they would normally be.
          </p>

          <h3 ref={thirdRef}>3. RETHINK HOW YOU MANAGE PERFORMANCE</h3>

          <p>
            If you’re not seeing all of your team every day, it can make performance review and
            management a little more difficult.
          </p>
          <p>
            However, when working from home became the norm last year, technology stepped up
            its game and gave us all the tools we could ever ask for to help us stay connected and
            collaborate. Make sure you are using technology to your advantage i.e. discussion
            boards, project planning, murals. Working in this way is fun, increases creativity,
            enhances innovative thinking and problem solving ability.
          </p>
          <p>
            If you haven’t got software in place to help manage your team’s performance and
            activity, there are lots of tools out there to help you.
          </p>
          <p>
            But instead of measuring ‘time’ spent in the office, you may want to reconsider the
            metrics you’re using to measure employee performance.
          </p>
          <p>
            You may want to track the following instead:
          </p>
          <ol>
            <li>1. Business outcomes</li>
            <li>2. Employee behaviours</li>
            <li>3. Important activities</li>
          </ol>
          <p>
            By tracking these metrics instead, it’s less about being stuck to your chair from 9 till 5
            and more about outputs to achieve greater performance - regardless of how much
            time is invested. This is both the essence and benefit of hybrid working.
          </p>
          <p>
            Encourage time blocking and 'down time' to provide time to think and digest
            information or prioritise work. Get rid of the notion that we can continually multi-task,
            this only leads to overwhelm, and believe us this is much harder to stop than it is to
            prevent in the first place!
          </p>
          <p>
            As a side note, make sure that all of your managers know exactly what the measures of
            success you’re looking for are, and what success looks like to you. This could be
            anything from attracting new clients, to ensuring that everyone is taking regular breaks
            and clocking off when they should.
          </p>
          <p>
            Again, you should be able to find the right apps or software to help you
            measure performance easily and accurately. Good HR software may be
            a good solution to keep everything in one place.
          </p>

          <h3 ref={fourthRef}>4. FOCUS ON TEAM HAPPINESS</h3>

          <p>
            Just because people are working away from
            the office, don’t forget to do the things you
            would do if they were in the office. It’s not ‘out
            of sight, out of mind’.
          </p>
          <p>
            Check in with your team regularly but make
            the conversation all about them, working on a
            70/30 split - with you listening at least 70% of
            the time. Be aware that you 'set the weather'
            so keep the energy high and your team will
            mirror the positive behaviour you display.
          </p>
          <p>
            It’s worth remembering that while some
            people flourish when working on their own,
            others get on better when they have more
            contact with colleagues. It can be very easy to
            start feeling isolated when working from home
            so take the time to know your teams 'social
            styles'.
          </p>
          <p>
            While most people can recognise which
            environments make them happiest,
            sometimes it might be down to you to identify
            an issue. Speaking with everyone regularly can
            help you to do that. Make sure you have your
            'sensory acuity' turned up to max.
          </p>
          <p>
            Schedule team meetings via Teams or Zoom at
            the start or end of each day, avoiding the
            middle of the day, for just 15 minutes and
            remember to maintain 1-2-1 meetings too. It’s
            not always easy to see what’s going on with
            individuals when there are lots of people
            involved in a conversation. If something falls
            out of the team meeting that is specifically
            involved, then take it off line and if there are
            any learnings or insights share it with the team
            as a 2 minute summary at the next meeting.
          </p>
          <p>
            This will allow others to get on with their work
            and stop any frustrations from those not
            involved.
          </p>
          <p>
            Ensure that training and development continues
            and isn’t sidelined for those who don’t work in
            the office all of the time. You'll need to deliver
            these in a hybrid way that suits everyone. It’s
            really important that CPD targets and goals are
            still in place and that individuals are able to keep
            working towards these, as well as progress their
            career.
          </p>
          <p>
            If you have any wellness or mental health
            initiatives, carry on with them and make sure
            they are effective. The same goes for teambuilding, which can sometimes feel like 'forced
            fun' so think creatively about what this actually is
            and the outcome you are trying to achieve. In
            fact, these things are even more important while
            your team is split. It gives them a chance to
            bond, build trust and get to know how they can
            help and work more closely together, keeping
            everyone feeling more motivated and engaged.
          </p>
          <p>
            Along those same lines, it is really important to
            ensure that people are taking breaks and
            making the appropriate time for lunch while
            they’re working remotely. Encourage 'time out'
            in the diary, and the use of techniques such as
            Pomodoro for big pieces of work. Also have a
            look at some useful apps like Wakeout to
            regulate time at the screen.
          </p>
          <p>
            Contrary to popular belief, people who work
            from home actually end up putting in more
            hours than they would in the office and find it
            harder to switch off from work when it’s time to
            shut down.
          </p>
          <p>
            And, although travel is still restricted, holiday
            entitlements are still in place. Encourage
            everyone to use their entitlement and make
            sure that, even if they’re holidaying at home,
            they switch off entirely from work and use the
            break to relax and re-energise.
          </p>
          <p>
            Look for signs of Covid-burnout in all your
            people and follow the above steps to help
            prevent it.
          </p>

          <h3 ref={fifthRef}>5. MAKE SURE YOU’RE TECH-READY</h3>

          <p>
            While we’re not unfamiliar with a working from
            home set up these days, it is important that
            everyone is using the right equipment and tools to
            get the job done efficiently and safely.
          </p>
          <p>
            Check that your IT infrastructure is up to the job of
            handling people who are switching from remote to
            office working regularly. This will include VPN access
            and good cyber security measures (another side
            effect of the pandemic has been a surge in cybercrime and, when you have people working from
            different locations using different devices, you’re at
            a higher risk of attack).
          </p>
          <p>
            Re-assess the tools you’re using to stay in touch and
            collaborate on projects. Could things be made
            easier? If you were using a makeshift solution, now
            is the time to invest in something long-term.
          </p>
          <p>
            Look for tools that will allow your team to easily stay
            in touch by email, message, video calls, etc., to share
            and edit files and to work together a lot more easily.
            There are lots of solutions out there and your IT
            department or provider will be able to recommend
            the most suitable one for you.
          </p>
          <p>
            Most of all make sure your team have stable and
            suitable internet provision. There's nothing more
            frustrating than people disconnecting when you're
            trying to have a productive meeting or development
            session.
          </p>

          <h3>FINAL THOUGHTS</h3>

          <p>
            There’s a lot to consider when it comes to creating a
            permanent hybrid team; however, if you follow the
            correct steps and take the time to plan the change
            correctly, it could be a great way to get the most from
            your team, build engagement and develop high
            performance.
          </p>
          <p>
            If you would like any further advice or guidance on
            creating your own hybrid team, or the policies that
            should accompany it, we’d love to help. Simply give us
            a call or drop us an email to arrange a conversation.
          </p>
        </div>
        <div className={styles.SectionSide}>
          <div className={styles.Download}>
            <a download href={articlePDF}>
              <i>
                <FontAwesomeIcon icon={faCloudDownloadAlt} />
              </i>
              Download Article
            </a>
          </div>

          <div className={styles.SectionSideInfo}>
            <h4>Found This Article Useful?</h4>
            <p>If you found this article or any of our FREE resources useful then sign up now to start using our amazing new features and tools, and take advantage of our <Link to={"/Packages/Silver"}>Silver</Link> and <Link to={"/Packages/Silver"}>Gold</Link> Packages!</p>

            <div className={styles.Download}>
              <Link to="/Register">
                Create an Account
              </Link>
            </div>
          </div>

          <h4><span>Or</span></h4>

          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default HybridWorking;
