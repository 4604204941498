import React from 'react';
import HybridWorking from '../../Views/Website/Articles/Hybrid-Working/Hybrid-Working';
import Layout from '../../components/Layout/layout';

const HybridWorkingPage: React.FC = (props: any) => {
  return (
    <Layout>
      <HybridWorking location={props.location.href} />
    </Layout>
  );
};

export default HybridWorkingPage;
